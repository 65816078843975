<template>
    <b-container fluid="xl">
        <div v-if="pageData===null" class="empty-div"></div>
        <div v-else>
            <transition
                name="slide-up"
                mode="out-in"> 
                <b-row :key="pageData.name">
                    
                    <div class="col-12">
                        <Title  
                            :title="pageData.name" 
                            :description="pageData.short_description"
                            :carousel="pageData.information_image"
                            button="null"
                            />
                        <SimpleLine/>
                    </div>
                    <!-- side menu block starts here-->
                    <div
                        v-if="isSideMenu" 
                        :class="isSideMenu ? 'col-12 col-md-5' : 'col-12'">
                        <div class="side-menu">
                            <div class="side-menu__container">
                                <!-- (1) parent loop starts here -->
                                <!-- COLLAPSE CONTROLLS HERE -->
                                <div
                                    v-for="(menu, idx) in sideMenu"
                                    :class="visible ? null : 'collapsed'"
                                    aria-controls="sidebar-collapse"
                                    :key="'menu' + idx"
                                    class="side-menu__item side-menu__item--main collapsed">                            
                                    <a
                                        :href="baseUrl + languages[currentLangId].code + '/' + menu.target_link"> 
                                        {{menu.name}}
                                        
                                    </a>
                                    <div
                                        @click="visible = !visible"
                                        class="side-menu__container--btn"
                                        :class="visible ? 'side-menu__container--btn--open' : ''">
                                    </div>
                                    
                                    <!-- COLLAPSE STARTS HERE -->
                                    <b-collapse 
                                        class="side-menu__collapse" 
                                        id="sidebar-collapse"
                                        v-model="visible">
                                        <!-- (2) first child menu starts here -->
                                        <div v-if="menu.children">
                                            <div 
                                                v-for="(submenu, id) in menu.children" 
                                                :key="'submenu' + id"
                                                class="side-menu__item side-menu__item--sub">                             
                                            <a
                                                @click="setNavItemGTM(menu.name, submenu.name)" 
                                                :href="baseUrl + languages[currentLangId].code + '/' + menu.target_link + '/' + submenu.target_link">
                                                {{submenu.name}}
                                            </a>
                                                <!-- (3) 2nd child menu starts here -->
                                                <div v-if="submenu.children">
                                                    <div 
                                                        v-for="(subsubmenu, i) in submenu.children" :key="'subsubmenu' + i"
                                                        class="side-menu__item side-menu__item--sub-sub">                             
                                                    <a
                                                        @click="setNavItemGTM(menu.name, subsubmenu.name)"
                                                        :href="baseUrl + languages[currentLangId].code + '/' + menu.target_link + '/' + submenu.target_link + '/' + subsubmenu.target_link">
                                                        {{subsubmenu.name}}
                                                    </a>
                                                    </div>
                                                </div> <!-- (3) end -->
                                            </div>
                                        </div> <!-- (2) end -->
                                    </b-collapse> <!-- COLLAPSE END -->

                                </div> <!-- (1) end -->
                            </div>
                        </div>
                        
                        <SimpleLine 
                            v-if="isSideMenu"
                            class="side-menu__decor"/>
                    </div><!-- side menu block ends here -->

                    <div :class="isSideMenu ? 'col-12 col-md-7' : 'col-12'">
                        <div class="inner-container">
                            <div v-if="pageData.description"
                                class="page__html" 
                                v-html="pageData.description">
                            </div>
                            <DocumentItem 
                                v-if="pageData.id==4"
                                :data="pageData.information_document"
                            />
                        </div>
                    </div>    
                    <div class="col-12">
                        <SimpleLine v-if="pageData.description"/>
                        <Contact />
                        <FooterBanner/>
                    </div>
                    <!-- <pre>
                        {{pageData}}
                    </pre> -->
                </b-row>
            </transition>
        </div>
    </b-container>    
</template>

<script>
import * as CONFIG from '@/setup/init.js'
import Title from '@/components/Title.vue'
import DocumentItem from '@/components/DocumentItem.vue'
import SimpleLine from '@/components/decors/SimpleLine.vue'
import Contact from '@/components/Contact'
import FooterBanner from '@/components/FooterBanner'
import apiH from '@/mixins/apiHandlerMI.js'
export default {
    mixins: [apiH],
    components: {
        Title,
        SimpleLine,
        Contact,
        DocumentItem,
        FooterBanner
    },
    data() {
        return {
            pageData: null,
            // menuItems: [],
            sideMenu: [],
            isSideMenu: false,
            parentNumber: Number,
            currentPath: String,
            visible: '',
            width: window.innerWidth,
            languages: CONFIG.languages  
        }
    },
    computed:{
        currentLevel0(){
            return this.$route.params.slug
        },
        currentLevel1(){
            return this.$route.params.sub_slug
        },
        currentLevel2(){
            return this.$route.params.sub_sub_slug
        },   
        menuItems()     {
            return this.$store.state.menu
        }
    },
    methods: {
        getData(slugName) {
            var _this = this
            _this.get('information/' + slugName, {
                params: {                        
                    relations: 1,
                    where: [['where','status', '1']],   
                    order_by:[
                        ['created_at','desc']                
                    ],
                    relations_params: {
                        "information_document":{
                            order_by: [
                                ['sort_order','asc']
                            ]
                        }
                    }                    
                }
                },
                function(resp){
                    _this.pageData = resp.data.information[0]
                    _this.metaTags()
                    _this.populateSideMenu()
                }, function(err){
                    console.log(err)
                    _this.$router.push({name:'404-page'})
                    _this.pageData = null          
                }
            )
        },
        cutUrl() {
            let thePath = this.$route.path 
            this.parentNumber = thePath.split('/').length-2
            this.currentPath = thePath.substring(thePath.lastIndexOf('/') + 1)
            this.getData(this.currentPath)
            // this.getMenuData()
        },
        populateSideMenu(){
            let _this = this
            this.isSideMenu = false
            this.sideMenu = []
            
            var currentParentMenuItem = this.menuItems.find( item => item.target_link.split('/')[item.target_link.split('/').length - 1] === _this.currentLevel0)
            // console.log('route', _this.currentLevel0)
            // console.log('currentParentMenuItem', currentParentMenuItem)
            this.sideMenu.push(currentParentMenuItem)
            if(this.sideMenu[0].children) {
                this.isSideMenu = true
            }
        },
        metaTags(){
            let _this = this

            this.setMetaTags({
                title: _this.pageData.meta_title, 
                desc: _this.pageData.meta_description, 
                keywords: _this.pageData.meta_keywords, 
                ogType: 'website', 
                ogTitle: _this.pageData.meta_title,
                ogImage: _this.pageData.meta_image, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc:_this.pageData.meta_description
            })
        },
        setNavItemGTM(menu, submenu){    
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'submenu',
                'action': menu + '_click',
                'label': submenu
            })
        },
        onResize(){
            this.width = window.innerWidth
            this.width > 768 
                ? this.visible = true
                : this.visible = false
        }
    },
    created(){
        this.cutUrl()        
        window.addEventListener("resize", this.onResize)
        this.onResize()        
        // this.populateSideMenu()
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize)
    },
    watch:{
        $route(){
            this.cutUrl()
            // this.populateSideMenu()
            window.addEventListener("resize", this.onResize)
            this.onResize()
        }
    }
}
</script>