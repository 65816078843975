<template>
    <div>
        <div class="document-item"
            v-for="(item, idx) in data"
            :key="'item' + idx">
            <div class="document-item__title">
                <h4>{{item.name}}</h4>
            </div>
            <div class="document-item__line"></div>
            <div class="document-item__link">
                <a :href="src_url + item.document_id.path + item.document_id.file_name" target="_blank">
                    <p>{{item.document_id.file_name}}</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data'],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME,
        }
    }
}
</script>